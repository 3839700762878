import React, { useEffect, useState } from "react"
import * as styles from "../dashboard.module.css"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import { exclusiveProperty, imageLike, imageLikeList } from "../../../components/Api/ListingApi"
import { useSelector } from "react-redux"
import AcceptPopup from '../../../components/popup/AcceptPopup';
import axios from 'axios';
import Cards from "../../../components/card/card"
import SkeletonVerticalCard from "../../../components/card/skeletonVerticalCard"
import { createBrowserHistory } from 'history';


function ExclusiveProperty() {
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 4,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    }
    const history = createBrowserHistory();
    const [exclusiveProp, setExclusiveProp] = useState([])
    const userDetails = useSelector(state => state.user.userDetail);
    
    const [openPopup, setOpenPopup] = useState(false)
    const [message, setMessage] = useState("")
    const [loader, setLoader] = useState(false);
    
    const [likes, setLikes] = useState([])
    const [openProperty, setOpenProperty] = useState(false)
    const [propertyId, setPropertyId] = useState("")
    const [propertDetails, setPropertyDetails] = useState({})
    const array = ["1", "2", "3", "4"]
    useEffect(() => {
        async function fetchData() {
            getGeoInfo()
           
        } fetchData()
    }, []);
    
    useEffect(() => {
        let userId = localStorage.getItem("userId")
        handleImageLikeList(userId)
        if( userId==null){
            getGeoInfo()
            
        }
    }, [])
    const getGeoInfo = () => {
        let userId = localStorage.getItem("userId")

        axios.get("https://ipapi.co/json/").then((response) => {
            let data = response.data;
            let getParams = {
                // longitude: -121.8401,
                // latitude: "37.3313",
                longitude: data.longitude,
                latitude: data.latitude,

            }
            // console.log("userDetails", userId)
            if (userId !== null) {
                // if (userDetails.role === "agent") {
                    getParams.agent_id = userDetails._id;
                // }
            }
            setLoader(true)
            exclusiveProperty(getParams).then(res => {
                setLoader(false)
                setExclusiveProp(res.data.data)
                // console.log("res.data.data", res.data.data)
            })
        }).catch((error) => {
            console.log(error);
        });
    };
    const hanldeOpenPopup = () => {
        setOpenPopup(p => !p)
        // console.log("check1")

    }
  

    const handleLike = async (id) => {
        let userId = localStorage.getItem('userId')
        // setIsFavorite(false)
        if (userId !== null) {
            await imageLike(userId, id).then((res) => {
                getGeoInfo()
                handleImageLikeList()
                // console.log("check2")

            }).catch((error) => {
                console.log('error', error)
            })
        }
        else {
            setMessage("In order to like this property details")
            hanldeOpenPopup()
            setTimeout(() => setOpenPopup(false), 3000)
            // console.log("check3")

        }
    }

    const handleImageLikeList = async () => {
        let userId = localStorage.getItem('userId')
        var value = []
        if (userId !== null && userId !== undefined) {
            await imageLikeList(userId).then((res) => {
                setLikes(res.data.data)
                value = res.data.data
            }).catch((error) => {
                console.log(error)
            })
            return value
        }
    }
    // hanldeOpenDetails(id, data)
    // console.log("userDetails",userDetails)
    const hanldeOpenDetails = (id, data) => {
        
        let userId = localStorage.getItem('userId')
        if (userDetails.role === "user") {
            setOpenProperty(p => !p)
            setPropertyId(id)
            setPropertyDetails(data)
            // history.push(`/${data.address}`)
            window.open(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`, "_blank")
            // console.log("check4")

        } else if (userDetails.role === "agent") {
            setOpenProperty(p => !p)
            setPropertyId(id)
            setPropertyDetails(data)
            // history.push(`/${data.address}`)
            window.open(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`, "_blank")
            // console.log("check5")

        }
        else {
            hanldeOpenPopup()
        // console.log("check6")

        }

    }



    return (
        <>
            <AcceptPopup success={openPopup} toggleSuccess={hanldeOpenPopup} claimAlertMessage={true} message={message} />
          
            <div className="px-5">
                {exclusiveProp.length !== 0 && <h3 className={styles.exclusiv}>Exclusive Properties</h3>}
                <Carousel
                    swipeable={false}
                    draggable={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px">
                    
                    {loader ? array.map((item) => (
                        <div className="mt-3">
                            <div className={styles.CarouselCard1}>
                                <SkeletonVerticalCard purpose={"card"} />
                            </div></div>
                    )) :
                        exclusiveProp.map((data, index) => {
                            return (
                                <div key={index}>
                                    <div className={styles.CarouselCard1}>
                                        <Cards data={data} I={index} loader={() => setLoader(true)} reLoadPage={getGeoInfo} logout={handleLike} handleLike={() => hanldeOpenDetails(data._id, data)} id={data._id} likes={likes.includes(data._id)} />
                                    </div>
                                </div>
                            )
                        })}
                </Carousel>
            </div>
            
        </>
    )

}
export default ExclusiveProperty