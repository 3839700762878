import React, { useState } from 'react'
import { Card } from "react-bootstrap"
import * as styles from "../../pages/seller/sellerProp.module.css"
import { imageLike } from '../Api/ListingApi'
import fav from "../../../static/images/fav.png"
import fav_select from "../../../static/images/fav-select.png"
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { handleCheckBrokarage } from '../../common/GenericFunction'
import { LikeDislike, PropertyClick, getListingStatus, formatPrice, getStatusColor } from '../../common/GenericFunction'
import cardmailImage from "../../../static/images/cardemailImage.png"
import AvatarImage from "../../components/Avatar/avatarImage"
import coldwell from "../../../static/images/coldwell.png"
import intero from "../../../static/images/intero.png"
import kellerwilliams from "../../../static/images/kellerwilliams.png"
import compass from "../../../static/images/compass.png"
import theagency from "../../../static/images/theagency.png"
import newBrokaragelogo from "../../../static/images/keywelogocard.png"
import ChatPopup from '../popup/ChatPopup';
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"



export default function Cards(props) {

    const [likes, setLikes] = useState(props.likes == true)
    const [spinner, setSpinner] = useState(false)
    const userDetails = useSelector(state => state.user.userDetail);
    const userlocation = useSelector(state => state.user.userLocation);
    let userId = localStorage.getItem('userId')
    const item = (props.data.property_id === undefined || props.data.property) ? props.data : props.data.property_id
    const { check } = props
    const [loader, setLoader] = useState(false);
    var addressData = item.address;


    //split
    var addressSplit = addressData !== undefined && addressData.split(", ")
    var addressOne = addressSplit[0] + ","
    var addressTwo = addressSplit[1] + ", " + addressSplit[2]

    const hanldeLike = (id) => {
        if (userId !== null) {
            setSpinner(true)
            imageLike(userId, id).then((res) => {
                setLikes(res.data.data.includes(id))
                setSpinner(false)
                if (check == "Interest") {
                    props.reLoadPage("like")
                }
                else if (props.isLiked == undefined && props.isLiked) {
                    props.reLoadPage("like")
                }
            })
        }
        else {
            props.logout()
        }
    }

    const handleLikes = (data) => {
        if (props.handleLike !== undefined) {
            props.handleLike(props.id === undefined ? data : props.id)
        }
    }

    var dataValue = ""
    var shouldUseCrossOrigin = false
    const handleImage = (imgValue, address) => {

        if (imgValue == undefined || imgValue == "" || imgValue == null) {
            var address1 = address !== null && address !== undefined && address.replace("#", "")
            shouldUseCrossOrigin = false
            dataValue = `https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`
            return dataValue
        }
        else {
            if (imgValue !== null && imgValue.split('/')[2] == "gokeywe.com") {
                shouldUseCrossOrigin = true
                dataValue = imgValue
                return dataValue
            }
            if (imgValue !== null && imgValue.split('/')[0] == "uploads") {
                shouldUseCrossOrigin = true
                dataValue = process.env.API_IMAGE_URL + imgValue
                return dataValue
            }
            else {
                dataValue = imgValue
                shouldUseCrossOrigin = false
                // console.log(dataValue);
                return dataValue
            }
        }
    }

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove all non-numeric characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Use regex to capture groups of 3-3-4 digits

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`; // Format number as (333) 333-4444
        } else {
            return '';
        }
    };
    const hanldeImages = img => {
        let data = img !== null && img.split("/")[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        } else {
            return img
        }
    }
    var customerEmail = item?.agent_id?.email ? item.agent_id.email : item?.listing_agent_id?.email ? item.listing_agent_id.email : item?.listing_agent_id?.email ? item.listing_agent_id.email : ""
    const chattingUserData = useSelector(state => state.dashboard.chat_disconnect);
    const [openChat, setOpenChat] = useState(false)
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const handleChat = async (id, name, lastName, image) => {
        if (item?.agent_id?._id == userId) {
            toast.error("You are the listing agent for the property, So you are not available for a chat.", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
        }
        else {
            let names = name
            await chattingUserData !== null && chattingUserData.disconnectUser()
            const apikey = process.env.STREAM_CHAT_APIKEY;
            const chatClient = StreamChat.getInstance(apikey);
            await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
            await chatClient.disconnectUser()
            setChatAgent({ id: id, name: names, image: image })
            setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
            setChatId(id + userDetails._id)
            showChatDetail()
        }
    }
    const showChatDetail = () => {
        if (openChat === false) {
            setLoader(true);
        }
        else {
            setLoader(false);
        }
        setOpenChat(p => !p)
    }
    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }

    return (
        <>
            <ToastContainer />
            {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}
            <Card className={styles.carouselCard} style={{ borderRadius: "25px", cursor: "pointer", boxShadow: "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)", border: props.selectedPropertyId === item._id ? "2px solid rgb(4, 144, 251)" : "1px solid white" }}>
                <div className={styles.favorite}>
                    <img src={handleImage(item !== undefined && item.thumbnail_image, item !== undefined && item.address)} alt="EditGroup1 " onClick={() => { handleLikes(item); PropertyClick("Property click", userDetails, item, userlocation) }} className={styles.carouselImage}{...(shouldUseCrossOrigin ? { crossOrigin: "anonymous" } : "")} />
                    <div className={styles.favorite1} onClick={() => { hanldeLike(item._id); LikeDislike("Favorite/UnFovorite", userDetails, item, userlocation) }} aria-hidden>
                        {(userDetails?.role !== "agent" && (window.location.pathname.includes("dashboard") || window.location.pathname == "/" || window.location.pathname === "/interestedproperty")) &&
                            <div>
                                {spinner ?
                                    <Spinner animation="border" variant="secondary" style={{ margin: "5px 0px 0px 0px", height: "1.8rem", width: "1.8rem" }} /> :
                                    (likes) ?
                                        <img src={fav_select} style={{ margin: "5px 0px 0px 0px" }} alt="like"></img>
                                        : <img src={fav} style={{ margin: "5px 0px 0px 0px" }} alt="like"></img>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="row m-0" onClick={() => { handleLikes(item); PropertyClick("Property click", userDetails, item, userlocation) }} onKeyPress={() => handleLikes(item)}>
                    <div className="col-md-8 cardtext1">
                        {(userId == null && item.listing_status === 3) ?
                            <>
                                <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}>{item.city}</p>
                                <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}> &nbsp;  &nbsp;</p> </>
                            :
                            userDetails?.agent_list?.length == 0 ?
                                <>
                                    <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}>{item.city}</p>
                                    <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}> &nbsp;  &nbsp;</p> </> :

                                ((item.companyID !== userDetails.companyID) && userDetails.role !== "user") ?
                                    <>
                                        <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}>{item.city}</p>
                                        <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}> &nbsp;  &nbsp;</p> </> :
                                    <div>
                                        {(item.listing_status === 3 && item.brokerage_exclusive !== false && userDetails.role === "user" && handleCheckBrokarage(item.companyID, userDetails) && window.location.pathname !== "/listproperty") ?
                                            <>
                                                <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}>{item.city}</p>
                                                <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}> &nbsp;  &nbsp;</p> </>
                                            : <>
                                                <p className='addrList'>{addressOne}</p>
                                                <p className='addrList'>{addressTwo}</p></>}</div>}
                    </div>
                    <div className="col-md-4 cardtext1">
                        <div className={styles.dashboardActive} id={styles.ActiveIconsStatus}>
                            {item.listing_status === 3 ?
                                <img src={item.companyID === "1" ? newBrokaragelogo : item.companyID === "2" ? newBrokaragelogo : item.companyID === "3" ? newBrokaragelogo : item.companyID === "4" ? newBrokaragelogo : item.companyID === "5" ? newBrokaragelogo : newBrokaragelogo} alt='mlsdotimage' className='mlsImage' /> :
                                <i className="fa fa-circle" style={{ color: item !== null && getStatusColor(item.listing_status) }} ></i>
                            }
                            <p className={styles.listingStaus} style={{ fontSize: "14px" }}>{getListingStatus(item.listing_status)}</p>
                        </div>
                    </div>
                </div>
                {((item.companyID !== userDetails.companyID) && userDetails.role !== "user") ? null
                    :
                    <div className="row m-0" style={{ display: "flex", marginTop: "5px" }} onClick={() => { handleLikes(item); PropertyClick("Property click", userDetails, item, userlocation) }} >
                        {item.listing_status == 5 ? <div className="col-md-6 ">
                            <p className={styles.homePrice} id={styles.pricedetail}>${formatPrice((item.sale_price !== undefined && item.sale_price.$numberDecimal !== null && item.sale_price.$numberDecimal !== "" && item.sale_price.$numberDecimal !== "0") ? item.sale_price.$numberDecimal : item.price.$numberDecimal
                            )}</p>
                        </div> :
                            <div className="col-md-6 ">
                                {(item?.priceRangeMax?.$numberDecimal !== "0" && item?.priceRangeMax?.$numberDecimal) ?
                                    <p className={styles.homePrice} id={styles.pricedetail}>${formatPrice(item.priceRangeMin.$numberDecimal)} - ${formatPrice(item.priceRangeMax.$numberDecimal)}</p> :
                                    <p className={styles.homePrice} id={styles.pricedetail}>${formatPrice(item?.price?.$numberDecimal ? item.price.$numberDecimal : item?.assessor_last_saleamount ? item.assessor_last_saleamount : "")}</p>}
                            </div>}
                        <div className="col-md-6 cardtext2 containers" style={{ marginBottom: "0px" }}>
                            <div className={styles.beds}>
                                <div><p style={{ fontFamily: "DejaVuSansBold" }} className={styles.bedsstyle}>{item !== null && item.beds}</p>
                                    <p style={{ color: "#898686" }} className={styles.bedsstyle2}>Beds</p>
                                </div>
                                <div>
                                    <p style={{ fontFamily: "DejaVuSansBold" }} className={styles.bathsstyle}>{item !== null && item.baths}</p>
                                    <p style={{ color: "#898686" }} className={styles.bathsstyle2}>Baths</p>
                                </div>
                                <div>
                                    <p className={styles.sqftstyle}><b>{formatPrice(item !== null && item.square_feet !== undefined && item.square_feet.$numberDecimal)}</b></p>
                                    <p style={{ color: "#898686" }} className={styles.sqftstyle2}>Sq.</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                <>
                    {userId !== null &&
                        <> {userDetails?.agent_list?.length !== 0 &&
                            <div className='row m-0 mls-sec' >
                                <div className='col-md-3' >
                                    {item?.agent_id?.img ?
                                        (item?.agent_id?.img ?
                                            (item?.agent_id?.img?.split("/")[0] === "uploads" ?
                                                <img className='mlsimage1' src={hanldeImages(item?.agent_id?.img ? item.agent_id.img : "")} alt="mls2Image" crossOrigin="anonymous"  ></img> :
                                                <img className='mlsimage1' src={hanldeImages(item?.agent_id?.img ? item.agent_id.img : "")} alt="mls2Image" ></img>) :
                                            <div className="agentAvatartext1 me-2" >
                                                <AvatarImage fontSize={"38px"} data={item?.agent_id?.name ? item.agent_id.name : "Unknown"} />
                                            </div>) :
                                        (item?.listing_agent_id?.img ?
                                            (item?.agent_id?.img?.split("/")[0] === "uploads" ?
                                                <img className='mlsimage1' src={hanldeImages(item?.listing_agent_id?.img ? item.listing_agent_id.img : "")} alt="mls2Image" crossOrigin="anonymous" ></img> :
                                                <img className='mlsimage1' src={hanldeImages(item?.listing_agent_id?.img ? item.listing_agent_id.img : "")} alt="mls2Image" ></img>) :
                                            <div className="agentAvatartext1 me-2" >
                                                <AvatarImage fontSize={"38px"} data={item?.listing_agent_id?.name ? item.listing_agent_id.name : item?.listing_agent_id?.name ? item.listing_agent_id.name : "Unknown"} />
                                            </div>)}
                                </div>
                                <div className='col-md-5'>
                                    <p className='mlstext1'>{item?.agent_id?.name ? item.agent_id.name : item?.listing_agent_id?.name ? item.listing_agent_id.name : item?.listing_agent_id?.name ? item.listing_agent_id.name : ""}</p>
                                    <p className='mlstext1'>{item?.agent_id?.brokerage_name ? item.agent_id.brokerage_name : item?.agent_id?.office_name ? item.agent_id.office_name : item?.listing_agent_id?.office_name ? item.listing_agent_id.office_name : item.companyID == "1" ? "Colwell Banker Reality" : item.companyID == "2" ? "Keller williams" : item.companyID == "3" ? "Compass" : item.companyID == "4" ? "Intero Real Estate Services" : item.companyID == "5" ? "The agency" : ""}</p>
                                    <p className='mlstext1' style={{ color: "#0490FB" }}>{formatPhoneNumber(item?.agent_id?.phone_number ? item.agent_id.phone_number : item?.listing_agent_id?.phone_number ? item.listing_agent_id.phone_number : item?.listing_agent_id?.phone_number ? item.listing_agent_id.phone_number : "")}</p>
                                </div>
                                <div className='col-md-4 d-flex justify-content-around'>
                                    <img onClick={() => { handleChat(item.agent_id._id, item.agent_id.name, item.agent_id.name, item.agent_id.img ? hanldeImage(item.agent_id.img) : "") }} className='mt-3 chantImageCard' src="/images/ChatImage.png" alt="emailImage" ></img>
                              
                                    {customerEmail ? <a href={'mailto:' + customerEmail} ><img className='mt-3' src={cardmailImage} alt="emailImage" ></img></a> : ""}
                                </div>

                            </div>}</>}</>
            </Card >
        </>
    )
}
